

.faq {
  position: relative;
  padding: 118px 0 67px;
  overflow: hidden;
  @include media("<=430px") {
    padding: 80px 0 60px;
  }
  &::before {
   content: '';
   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 1960px;
   max-height: 1960px;
   height: 100%;
   background: #55D6C2;
   z-index: -1;
   border-radius: 900px 900px 0 0;
  @include media("<=810px") {
    width: 1700px;
  }
  }
    &::after {
      position: absolute;
      content: "";
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      top: 50%;
      width: 1140px;
      height: 1140px;
      border-radius: 50%;
      background: radial-gradient(circle, #EFE2D9 0%, rgba(239, 226, 217, 0) 70%);
      @include media("<=810px") {
        top: 0;
      }
    }

 .section__title {
  color: #583F00;
 }
}

.faq__inner {
  width: 100%;
  padding-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
}

.tabs, .faq__slider {
  position: relative;
  width: 100%;
  max-width: 966px;
  padding-right: 82px;
  margin-bottom: 36px;
  @include media("<=810px") {
    padding-right: 0;
    padding-bottom: 160px;
  }

    .slick-list {
      overflow: hidden;
      padding: 0 !important;
      height: 430px !important;
  
      @include media("<=810px") {
        
      }
  
      @include media("<=430px") {
        height: 476px !important;
      }
    }
  
    .slick-track {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
  
      @include media("<=810px") {
        
      }
  
      @include media("<=430px") {
       
      }
    }

.slick-slide {
  position: relative;
  cursor: pointer;
  transition: all 0.3s linear;
  opacity: 0.5;

  @include media("<=430px") {}
}

.slick-current {
  opacity: 1;
}

        .slick-arrow {
          position: absolute;
          z-index: 10;
          font-size: 0;
          background-repeat: no-repeat;
          background-size: 100% auto;
          background-position: center;
          width: 100%;
          max-width: 48px;
          max-height: 48px;
          height: 100%;
          right: 0;
          border: none;
          background-color: transparent;
          cursor: pointer;
    
          transition: all 0.3s linear;
    
          &:hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
          }
    
          @include media("<=810px") {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
          }
    
          @include media("<=430px") {
            
          }
    
          @include media("<=375px") {}
    
          @include media("<=320px") {}
    
          &.slick-prev {
            top: 38%;
            background-image: url(../images/up.png);
    
            @include media("<=810px") {
              top: auto;
              bottom: 78px;
            }
    
            @include media("<=430px") {}
          }
    
          &.slick-next {
            background-image: url(../images/down.png);
            bottom: 38%;
    
            @include media("<=810px") {
              bottom: 0;
            }
    
            @include media("<=430px") {}
          }
        }
}

.faq__item {
  width: 100%;
  overflow-x: clip;
  position: relative;
 
  @include media("<=430px") {
    
  }
}

.faq__text {
  width: 100%;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: none;
  max-height: 0;
  padding: 0 38px;
  transition: all 0.35s linear;
  background-color: #ffffff;
  border-radius: 30px;
  color: #BF892E;
  position: relative;
  top: -40px;
  z-index: -1;
  opacity: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(191, 137, 46, 0.25);
  @include media("<=810px") {}

  @include media("<=430px") {
    font-size: 16px;
    padding: 0 18px;
    
  }
}



.label {
  width: 100%;
  height: 86px;
  position: relative;
  z-index: 3;
  background-color: #ffffff;
  padding: 0 60px 0 39px;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(191, 137, 46, 0.25);
  cursor: pointer;
  @include media("<=810px") {

  }
  @include media("<=430px") {
    font-size: 18px;
    height: 119px;
    padding: 0 42px 0 18px;
    
  }
  &::after {
    content: "";
    position: absolute;
    top: 28px;
    right: 22px;
    width: 30px;
    height: 30px;
    background-image: url(../images/faq-icon.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
    transition: all 0.35s ease-in-out;

    @include media("<=430px") {
      right: 14px;
      top: 44px;
    }
  }
 p {
  max-width: 622px;
 }
  
}



input:checked {
  +.label {
    &::after {
      transform: rotate(135deg);
    }
  }

  ~.faq__text {
    max-height: 100vh;
    padding-top: 100px;
    padding-bottom: 49px;
    margin-top: -51px;
    top: auto;
    opacity: 1;
    
  }
}

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

