.winner {
  position: relative;
  overflow-x: clip;
  background-color: #FCFC94;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
   .section__title {
    color: #793F1B;
    max-width: 614px;
   } 
}

.winner__date {
  background-color: #55d6c2;
  padding: 20px 0;
  position: relative;
  z-index: 3;
  &-text {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    @include media("<=430px") {
      font-size: 16px;
    }
  }
  span {
    font-weight: 700;
  }
}

.winner__inner {
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
    
  }
    
}

.winner__content {
  width: 100%;
  max-width: 953px;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 3;
  margin-top: -60px;
  @include media("<=1080px") {
    
  }
    @include media("<=810px") {
      max-width: 600px;
      margin-top: -10px;
    }
    &::before {
      position: absolute;
      content: "";
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      bottom: -5%;
      width: 950px;
      height: 850px;
      border-radius: 50%;
      background: radial-gradient(circle, #EFE2D9 0%, rgba(239, 226, 217, 0) 70%);
  
      @include media("<=430px") {
        bottom: -30%;
        
        transform: translate(-50%, -50%);
        width: 750px;
        height: 750px;
      }
    }
}

.winner__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  padding-top: 14px;
 padding-bottom: 34px;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1080px") {
    font-size: 18px;
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
    font-size: 16px;
    
  }
  p {
    padding-bottom: 4px;
  }
  span {
    font-size: 36px;
    font-weight: 700;
    display: block;
    @include media("<=430px") {
    
        font-size: 26px;
    
      }
  }
}

.winner__image {
  flex: 0 1 100%;
  max-width: 432px;
  
  @include media("<=810px") {
    max-width: 550px;
  }
    @include media("<=430px") {
      max-width: 250px;
    }
  img {
    width: 100%;
  }
}

.winner__subtext {
  padding: 12px 26px 17px;
  max-width: 779px;
  background-color: #55D6C2;
  box-shadow: 0 4px 4px #00000031;
  color: #F7F3E8;
  font-size: 24px;
  border-radius: 34px;
  margin-bottom: -50px;
  @include media("<=810px") {
    font-size: 20px;
  }
  
    @include media("<=430px") {
  
      font-size: 18px;
  
    }
}

.winner__sign {
  background-color: #793F1B;
  padding: 80px 0 40px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  font-style: italic;
  @include media("<=430px") {
    font-size: 22px;
  }
}