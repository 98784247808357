.page {
  padding: 62px 0 71px;
  .section__title {
    color: #583F00;
  }
}

.page__title {
  text-align: center;
  color: #583F00;
  font-size: 20px;
  line-height: 1.2;
  b {
      font-weight: 700;
    }
}

.page__subtitle {
  font-weight: 700;
  font-style: italic;
  text-align: center;
  margin-top: 26px;
  color: #000000;
  font-size: 18px;
    line-height: 1.1;
  
    @include media("<=430px") {
      font-size: 16px;
    }
}

.page__inner {
  padding-top: 58px;
  position: relative;
  z-index: 3;
  color: #000000;
  text-align: justify;
  @include media("<=810px") {
    padding-top: 50px;
    
  }
}

.page__text {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
  line-height: 1.1;
  @include media("<=430px") {
    font-size: 16px;
  }
  b {
    font-weight: 700;
  }
}

.page__min {
  font-size: 16px;
  @include media("<=430px") {
    font-size: 14px;
  }
}
