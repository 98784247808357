.contacts {
    background-color: #F5E960;
    padding: 72px 0;

    @include media("<=810px") {
       
    }

    .section__title {
        @include media("<=810px") {
            text-align: center;
        }
    }
}

.contacts__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 41px;
@include media("<=1280px") {
    justify-content: center;
}
    @include media("<=810px") {
        flex-direction: column;
        row-gap: 30px;
    }

    @include media("<=430px") {
        
    }
}

.contacts__text {
    font-size: 40px;
    line-height: 1.2;
    @include media("<=1280px") {
        flex: 0 1 44%;
        font-size: 32px;
    }
  @include media("<=1080px") {
    font-size: 28px;
  }
  @include media("<=810px") {
    font-size: 32px;
    text-align: center;
  }
  @include media("<=430px") {
    font-size: 28px;
  }
}

.contacts__image {
    max-width: 154px;
    opacity: 0;
    transition: all 0.8s ease 0.2s;
    
        &._active {
            opacity: 1;
        }
    img {
        width: 100%;
        display: block;
    }
}

.contacts__links {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
}

.contacts__tel {
    font-size: 38px;
        line-height: 1.2;
        font-weight: 700;
        color: #BF892E;
        text-decoration: none;
        @include media("<=810px") {
            text-align: center;
        }
        @include media("<=430px") {
            font-size: 32px;
            line-height: 1.3;
        }
}