.features {
  padding-top: 159px;
  padding-bottom: 141px;
  overflow: hidden;
  @include media("<=810px") {
    padding: 100px 0;
  }
  .section__title {
    max-width: 872px;
    margin: 0 auto;
  }
  
}

.features__slider {
  padding-top: 100px;
  padding-bottom: 127px;
  width: 100%;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding-top: 60px;
  }

    .slick-list {
      padding: 0 15.1vw !important;
      @include media("<=1440px") {
        padding: 0 8vw !important;
      }
            @include media("<=1280px") {
              padding: 0 4vw !important;
            }
            @include media("<=1080px") {
              padding: 0 !important;
            }
            @include media("<=810px") {
              padding: 0 22vw !important;
            }
            @include media("<=430px") {
              padding: 0 20px !important;
            }
      @include media("<=430px") {}
    }
  
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 24px;
      @include media("<=430px") {
        column-gap: 15px;
      }
    }
  
    .slick-slide {
      position: relative;
      cursor: pointer;
      transition: all 0.3s linear;
  
  
      @include media("<=430px") {}
    }
  
    .slick-arrow {
      position: absolute;
      z-index: 10;
      font-size: 0;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      width: 100%;
      max-width: 48px;
      max-height: 48px;
      height: 100%;
      bottom: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
  
      transition: all 0.3s linear;
  
      &:hover {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
      }
  
      @include media("<=810px") {}
  
      @include media("<=430px") {
       
      }
  
      @include media("<=375px") {}
  
      @include media("<=320px") {}
  
      &.slick-prev {
        left: 46%;
        background-image: url(../images/prev.png);
      @include media("<=1080px") {
        left: 43%;
      }
        @include media("<=810px") {
          
          left: 40%;
        }
  
        @include media("<=430px") {
          left: 32%;
        }
      }
  
      &.slick-next {
        background-image: url(../images/next.png);
        right: 46%;
      @include media("<=1080px") {
        right: 43%;
      }
        @include media("<=810px") {
          right: 40%;
         
        }
  
        @include media("<=430px") {
          right: 32%;
        }
      }
    }
}

.features__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 419px;
  height: 378px;
  border-radius: 50px;
  padding-top: 27px;
  text-align: center;
  background-color: #ffffff;
  filter: drop-shadow(-12px 25px 50px rgba(186, 181, 216, 0.25));
  transition: all 0.8s ease 0.2s;
  @include media("<=1080px") {
    height: 350px;
  }
  
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    height: 330px;
    padding: 15px 5px;
    filter: drop-shadow(-6px 12px 25px rgba(186, 181, 216, 0.25));
  }
  &-image {
    max-width: 100px;
    width: 100%;
    margin-bottom: 38px;
    @include media("<=1440px") {
      margin-bottom: 20px;
    }
    @include media("<=810px") {
      margin-bottom: 20px;
    }
    @include media("<=430px") {
      max-width: 70px;
    }
    img {
      width: 100%;
      display: block;
    }
  }
  &-title {
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 16px;
    max-width: 347px;
    @include media("<=1280px") {
      font-size: 18px;
    }
        @include media("<=350px") {
          font-size: 16px;
        }
  }
  &-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    max-width: 326px;
    flex: 1 0 auto;
    @include media("<=1080px") {
      font-size: 14px;
      padding: 0 5px;
    }
    @include media("<=350px") {
      font-size: 12px;
    }
  }
}