.steps {
    padding-top: 66px;
    .section__title {
        color: #583F00;
    }
}

.steps__inner {
    padding-top: 88px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 43px;
    column-gap: 53px;
    @include media("<=810px") {
        flex-direction: column;
    }
  @include media("<=430px") {
    padding-top: 60px;
  }
}

.steps__item {
    max-width: 530px;
    display: flex;
    align-items: center;
    column-gap: 22px;
    opacity: 0;
        transition: all 0.8s ease 0.2s;
    
        &._active {
            opacity: 1;
        }
    @include media("<=1280px") {
        flex: 0 1 47%;
    }
        @include media("<=810px") {
            flex-direction: column;
            row-gap: 20px;
        }
        &:nth-of-type(2) {
          transition: all 0.8s ease 0.4s;
        }
        &:nth-of-type(3) {
            transition: all 0.8s ease 0.6s;
        }
        &:nth-of-type(4) {
            transition: all 0.8s ease 0.8s;
        }
}

.steps__num {
    width: 100%;
    max-width: 73px;
    font-size: 128px;
    font-weight: 800;
    color: #55D6C2;
    @include media("<=430px") {
        font-size: 100px;
    }

}

.steps__text {
    font-size: 24px;
    line-height: 1.3;
    @include media("<=1280px") {
        font-size: 20px;
    }
        @include media("<=810px") {
            text-align: center;
        }
        @include media("<=430px") {
            font-size: 18px;
        }
}