.info {
    padding-bottom: 141px;
    @include media("<=810px") {
        padding-bottom: 80px;
    }
    .section__title {
        color: #583F00;
    }
}

.info__inner {
    padding-top: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 74px;
    @include media("<=810px") {
        flex-direction: column-reverse;
        row-gap: 30px;
        padding-top: 40px;
    }
}

.info__image {
    flex: 0 1 100%;
    max-width: 302px;
    @include media("<=430px") {
        max-width: 200px;
    }
    img {
        width: 100%;
        display: block;
    }
}

.info__list {
    flex: 0 1 100%;
    max-width: 693px;
    display: flex;
    flex-direction: column;
    row-gap: 47px;
    @include media("<=430px") {
        row-gap: 20px;
    }
}

.list__item {
  display: flex;
  column-gap: 21px;
  align-items: flex-start;
opacity: 0;
    transition: all 0.8s ease 0.2s;

    &._active {
        opacity: 1;
    }
@include media("<=430px") {
    column-gap: 15px;
}
&:nth-of-type(2) {
    transition: all 0.8s ease 0.4s;
}

&:nth-of-type(3) {
    transition: all 0.8s ease 0.6s;
}

&:nth-of-type(4) {
    transition: all 0.8s ease 0.8s;
}
&:nth-of-type(5) {
    transition: all 0.8s ease 1s;
}
}

.list__icon {
    max-width: 32px;
    width: 100%;
}

.list__text {
    font-size: 20px;
    line-height: 1.2;
    @include media("<=430px") {
        font-size: 18px;
    }
}