.about {
  position: relative;
  padding: 53px 0 43px;
  overflow-x: clip;
  background-color: #EFE2D9;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding: 40px 0 50px;
  }
    .button {
      @include media("<=810px") {
        position: absolute;
        bottom: 0;
      }
    }
}

.about__inner {
  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 85px;
  width: 100%;
  z-index: 2;
  @include media("<=810px") {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 120px;
  }
  @include media("<=430px") {
    
  }
}

.about__content {
  padding-top: 51px;
  max-width: 575px;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media("<=1080px") {
    max-width: 430px;
  }
    @include media("<=810px") {
      max-width: 550px;
    }
}

.about__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  text-align: justify;
 padding-top: 32px;
 padding-bottom: 34px;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1080px") {
    font-size: 18px;
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
    font-size: 18px;
    
  }
  
}

.about__image {
  flex: 0 1 100%;
  max-width: 355px;
  
  filter: drop-shadow(-12px 25px 50px rgba(186, 181, 216, 0.25));
  @include media("<=810px") {
    max-width: 550px;
  }
    @include media("<=430px") {
      max-width: 250px;
    }
  img {
    width: 100%;
  }
}



.about__item {
  flex: 0 1 33%;
  max-width: 226px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  &:nth-of-type(2) {
    transition: all 0.8s ease 0.4s;
  }
    &:nth-of-type(3) {
      transition: all 0.8s ease 0.6s;
    }
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    flex: 0 1 50%;
    max-width: none;
    margin-bottom: 20px;
  }
    @include media("<=430px") {
      flex: 0 1 100%;
      max-width: 200px;
      width: 100%;
      margin-bottom: 30px;
    }
  &-text {
    padding-top: 69px;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    max-width: 133px;
    @include media("<=810px") {
      padding-top: 40px;
    }
    @include media("<=430px") {
      padding-top: 20px;
    }
  }
}

.about__number {
  max-width: 226px;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 15px #FF3509 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 900;
  @include media("<=430px") {
    font-size: 36px;
  }
}

