body {
  margin: 0;
  font-family: 'Mont';
  background-color: #F7F3E8;
  color: #583F00;
  position: relative;
  @include media("<=810px") {
    background-position-x: 67%;
    background-size: 380%;
  }
  @include media("<=430px") {
    background-size: 550%;
  }
  &.page {
    background-image: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {
  text-align: center;
  text-transform: uppercase;
  font-size: 48px;
  color: #BF892E;
  font-weight: 700;
  line-height: 1.1;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    font-size: 36px;
    
  }
  @include media("<=430px") {
    font-size: 32px;
  }
}

//BURGER BUTTON//
.burger__button {
  display: none;

  @include media("<=810px") {
    display: block;
    position: fixed;
    top: 26px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
    top: 24px;
    right: 20px;
  }

  @include media("<=810px") {
    &.active {
      position: fixed;
      top: 38px;
      right: 40px;
      z-index: 200;
    }
    @include media("<=430px") {
      &.active {
        top: 36px;
        right: 20px;
      }
    }
  }

  .line {
    height: 6px;
    width: 58px;
    margin-bottom: 8px;
    background-color: #583F00;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      height: 4px;
      width: 48px;
    }
  }

  &.active {
    .line {
      background-color: #583F00;
      &.one {
        transform: rotate(45deg) scale(1.2);
      }
      &.two {
        transform: rotate(-45deg) scale(1.2) translateY(-8px) translateX(8px);
        @include media("<=736px") {
          transform: rotate(-45deg) scale(1.2) translateY(-7px) translateX(7px);
        }
      }
      &.three {
        opacity: 0;
      }
    }
  }
}
