.header {
  width: 100%;
  padding: 15px 0;
  background-color: #FCFC94;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding: 15px 0 ;
  }
  &.page {
    position: relative;
  }
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
  column-gap: 15px;
  @include media("<=810px") {
    column-gap: 0;
  }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  max-width: 893px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=1080px") {
    max-width: 820px;
  }
  @include media("<=810px") {
    display: none;
    max-width: 100%;
    &.active {
      display: flex;
      position: fixed;
      height: 50%;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #FCFC94;
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 1;
      border-radius: 0 0 30px 30px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include media("<=430px") {
        padding-bottom: 0;
        
      }
      .header__link {
        padding: 15px;
        @include media("<=430px") {
          padding: 10px;
        }
        @include media("<=350px") {
          padding: 10px;
        }
      }
    }
  }
}

.header__link {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  color: #583F00;
  transition: all 0.3s ease;
  position: relative;
  @include media("<=810px") {
  }

  &:hover {
    color: #BF892E;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #583F00;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__tel {
  position: relative;
  padding-left: 48px;
  margin-left: 90px;
  @include media("<=1080px") {
    margin-left: 0;
  }
    @include media("<=810px") {
      margin-left: 0;
    }
  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../images/header-tel.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.header__tel-link {
  font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    text-decoration: none;
  color: #BF892E;
  transition: all 0.3s ease;
    position: relative;
    display: block;
    @include media("<=810px") {
      line-height: 1.4;
    }
    &:hover {
        color: #583F00;
        text-decoration: underline;
      }
}

.logo {
  display: block;
  max-width: 145px;
  width: 100%;
  flex: 0 1 100%;
  @include media("<=810px") {
    position: relative;
    z-index: 21;
  }
    @include media("<=430px") {
      max-width: 120px;
    }
  img {
    width: 100%;
    display: block;
  }
}

