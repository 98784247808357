.callback {
  background-color: #F5E960;
  padding: 72px 0;
  margin-bottom: 136px;
  @include media("<=810px") {
    margin-top: 0;
  }
  .section__title {
    color: #583F00;
    @include media("<=810px") {
      text-align: center;
    }
  }
}

.callback__inner {
  padding: 91px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 42px;
  @include media("<=810px") {
    flex-direction: column;
    padding: 70px 0;
  }
    @include media("<=430px") {
      padding-top: 40px;
    }
}

.callback__image {
  flex: 0 1 100%;
  max-width: 269px;
  opacity: 0;
    transition: all 0.8s ease 0.2s;
    @include media("<=810px") {
      margin-bottom: 30px;
    }

  &._active {
      opacity: 1;
    }
  img {
    width: 100%;
  }  
}

.callback__content {
  flex: 0 1 100%;
  max-width: 636px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.callback__title {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  color: #BF892E;
  text-align: center;
  @include media("<=430px") {
    font-size: 28px;
  }
}

.callback__links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  margin-bottom: 56px;
  @include media("<=1080px") {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 27px;
  }

  @include media("<=810px") {
    margin-top: 15px;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
}

.callback__tel {
  font-size: 36px;
  line-height: 1.2;
    font-weight: 700;
    color: #BF892E;
    text-decoration: none;
    @include media("<=430px") {
        font-size: 30px;
      }
}

.callback__text {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  text-align: justify;
  @include media("<=430px") {
      font-size: 18px;
    }
}
