.hero {
 background-color: #55D6C2;
  position: relative;
  z-index: 2;
  overflow-x: clip;
//  margin-top: -42px;
  @include media("<=810px") {
    padding-top: 80px;
    
  }
  @include media("<=430px") {
    padding-top: 60px;
  }
  .button {
    @include media("<=810px") {
      position: absolute;
      bottom: 80px;
    }
  }
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 608px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @include media("<=1080px") {
    max-width: 552px;
    
  }
  @include media("<=810px") {
    align-items: center;
    
  }
    @include media("<=430px") {
      margin-bottom: 5px;
    }
}

.hero__title {
  font-size: 100px;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    
  }

  @include media("<=1080px") {
    font-size: 90px;
  }
  @include media("<=810px") {
    text-align: center;
    margin-bottom: 20px;
    font-size: 11.5vw;
  }
  @include media("<=430px") {
    font-size: 13.5vw;
  }

  @include media("<=320px") {
  }
  img {
    width: 100%;
    display: block;
   
  }
}

.hero__subtitle {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 47px;
  line-height: 1.2;
  opacity: 0;
    transition: all 0.8s ease 0.4s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=1280px") {
    font-size: 44px;
  }
  @include media("<=1080px") {
    font-size: 40px;
  }
  @include media("<=810px") {
    font-size: 6vw;
    
  }
  @include media("<=430px") {
    font-size: 8vw;
    margin-bottom: 30px;
  }
    @include media("<=350px") {
      
    }

}

.hero__text {
  color: #FFFFFF;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 22px;
  opacity: 0;
  transition: all 0.8s ease 0.6s;
  
    &._active {
      opacity: 1;
    }
  @include media("<=810px") {
    margin-bottom: 0;
  }
  @include media("<=430px") {
    font-size: 16px;
  }
    @include media("<=350px") {
      font-size: 14px;
    }
}

.button {
  display: block;
  color: #583F00;
  background-color: #F5E960;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 29px;
  max-width: 316px;
  width: 100%;
  border-radius: 30px;
  transition: all ease-in-out 0.3s;
  border: 2px solid #F5E960;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  &:hover {
    box-shadow: 0 4px 10px #00000095;
   border: 2px solid #583F00;
  }
    @include media("<=430px") {
      padding: 22px;
      max-width: 230px;
      font-size: 18px;
    }
  &-callback {
    background-color: #55D6C2;
    color: #ffffff;
  }  
  &-faq {
    box-shadow: 0 2px 2px #827439;
  }
}

.hero__image {
  margin-top: -12px;
  flex: 0 1 100%;
  max-width: 446px;
  display: flex;
  align-items: flex-end;
  position: relative;
opacity: 0;
  transition: all 0.8s ease 0.6s;

  &._active {
    opacity: 1;
  }
  @include media("<=1080px") {
    max-width: 400px;
    left: -3%;
  }
  @include media("<=810px") {
    max-width: 500px;
    margin-top: 0;
    left: auto;
    justify-content: center;
  }
  @include media("<=430px") {
    max-width: 310px;
  }
    @include media("<=350px") {
     
    }
  img {
    display: block;
    flex: 0 1 100%;
    position: relative;
  }
    &::before {
      position: absolute;
      content: "";
      z-index: -1;
      left: 60%;
      transform: translateX(-50%);
      bottom: -50%;
      width: 1140px;
      height: 1140px;
      border-radius: 50%;
      background: radial-gradient(circle, #EFE2D9 0%, rgba(239, 226, 217, 0) 70%);
      @include media("<=430px") {
        bottom: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 750px;
        height: 750px;
      }
    }
}

.img-1 {
  max-width: 363px;
  z-index: 3;
  @include media("<=1080px") {
   width: 30vw;
  }
    @include media("<=810px") {
      right: -15%;
      width: 40vw;
    }
    @include media("<=430px") {
      width: 70vw;
      right: -28%;
    }
}

.img-2 {
  max-width: 441px;
  left: -34%;
  @include media("<=1080px") {
    width: 37vw;
  }
    @include media("<=810px") {
      left: -15%;
      width: 47vw;
    }
    @include media("<=430px") {
      width: 85vw;
      left: -18%;
    }
}




