.footer {
  background-color: #F5E960;
  padding: 66px 0;
  @include media("<=430px") {
    padding: 44px 0;
  }
  
}

.footer__inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  row-gap: 40px;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 30px;
  }
  @include media("<=430px") {
    
  }
}

.footer__contacts {
  display: flex;
  max-width: 760px;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: 70px;
  @include media("<=810px") {
    flex: 0 1 40%;
    align-items: center;
    width: 85%;
    column-gap: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 20px;
  }
    @include media("<=430px") {
      flex-direction: column;
    }
    @include media("<=350px") {
     flex: 0 1 44%;
    }
}

.footer__title {
  font-size: 16px;
  font-weight: 700;
  display: none;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    display: block;
  }
@include media("<=350px") {
  font-size: 16px;
}
}

.footer__link {
  font-size: 16px;
  font-weight: 400;
  color: #583F00;
  text-decoration: none;
  display: flex;
  column-gap: 20px;
  align-items: center;
  max-width: 142px;
  width: 100%;
  @include media("<=430px") {
    font-size: 14px;
    column-gap: 15px;
  }
@include media("<=350px") {
  font-size: 12px;
  justify-content: center;
}
  &:hover {
    text-decoration: underline;
  }
}

.footer__nav {
  display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 600px;
    width: 100%;
    @include media("<=810px") {
      order: 4;
      flex: 0 1 100%;
    flex-direction: row;
      justify-content: space-around;
      max-width: 600px;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 20px;
    }
    @include media("<=430px") {
      
    }
}

.footer__nav-link {
  font-size: 16px;
    font-weight: 600;
    color: #583F00;
    text-decoration: none;
    text-transform: capitalize;
    @include media("<=430px") {
      font-size: 14px;
    }
    @include media("<=350px") {
      font-size: 12px;
    }
    &:hover {
        text-decoration: underline;
      }
}

.footer__logo {
  position: relative;
  flex: 0 1 100%;
  max-width: 143px;
  width: 100%;  
  @include media("<=810px") {
    padding: 25px 0 47px;
  }
  @include media("<=430px") {
    max-width: 120px;
  }

  img {
    width: 100%;
  } 
}

